import React from 'react'
import ReactDOM from 'react-dom/client'
import { RouterProvider } from 'react-router-dom'
import { Provider } from 'react-redux'
import { PersistGate } from 'redux-persist/lib/integration/react'
import zhCN from 'antd/locale/zh_CN'
import { ConfigProvider } from 'antd'

import 'reset-css'
import '@styles/index.scss'
import antdThemeCustom from './utils/antdThemeCustom'
import router from './router'
import { store, persistor } from './store'

const root = ReactDOM.createRoot(document.getElementById('root'))
root.render(
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <ConfigProvider locale={zhCN} theme={antdThemeCustom}>
        <RouterProvider router={router} />
      </ConfigProvider>
    </PersistGate>
  </Provider>
)
