/*
 * @Descripttion: 
 * @version: 
 * @Author: yujiada
 * @Date: 2024-05-28 11:03:32
 * @LastEditors: yujiada
 * @LastEditTime: 2024-05-29 10:01:02
 */
import CryptoJS from 'crypto-js'
import JSEncrypt from 'jsencrypt'

/***
 * 以递归的方式展平数组对象
 * @param arr 数组对象
 * @param childKey 子级key
 */
export const flatArrayObj = (arr, childKey) =>
  arr.reduce((prev, item) => {
    let isChild = Array.isArray(item[childKey])
    if (isChild) {
      prev.push(item)
    }
    return prev.concat(isChild ? flatArrayObj(item[childKey], childKey) : item)
  }, [])

/***
 * 防抖
 * @param {Function} fn 延时调用函数
 * @param {Number} delay 延迟多长时间
 */
export const debounce = (fn, delay) => {
  let timer = null
  return function () {
    clearTimeout(timer)
    timer = setTimeout(() => {
      fn.apply(this, arguments)
    }, delay)
  }
}

/***
 * 节流
 * @param {Function} fn 延时调用函数
 * @param {Number} delay 延迟多长时间
 */
export const throttle = (fn, delay) => {
  let flag = true
  return function () {
    if (!flag) {
      return
    }
    flag = false
    setTimeout(() => {
      fn.apply(this, arguments)
      flag = true
    }, delay)
  }
}

//密码加密
/**
 * 
 * @param {*} data 明文密码
 * @returns 密文密码
 */
export function passwordSet(data) {
  const aesMessage = CryptoJS.enc.Utf8.parse(data);
  const aesPrivateKey = CryptoJS.enc.Base64.parse('48cfec19efe348b6aa25425aa14be7d5')
  const aesEncrypted = CryptoJS.AES.encrypt(
    aesMessage,
    aesPrivateKey, {
      mode: CryptoJS.mode.ECB,
      padding: CryptoJS.pad.Pkcs7
    }
  ); //aes密文
  let aesEncryptedBase64 = CryptoJS.enc.Base64.stringify(aesEncrypted.ciphertext).toString();
  const rsa = new JSEncrypt(); //构造RSA对象
  //RSA算法设置RSA公钥
  rsa.setKey('MIIBIjANBgkqhkiG9w0BAQEFAAOCAQ8AMIIBCgKCAQEAwFvoXb24XKACnyUX7bgulZTjw818uDGTA36r8GsiabAB/Cbyc+OKFBg1dTaDarORduCx8567y/Dxd/nq5K1qG0DVm/AtikCM8P1zZZqlQg+wMqMOhtsgc0aWVW5PVrmKOLJHHqlnXbLuB+UmAx0GrNT1UfowS5GmRKeTmKgK9BbJ4jZB+YFIlsKC7yyzIRj54rc48/Dpbq/Onii4GatENmktskzqhQmLpl3gB5uJtlH4/gsI417TbLbXlp199W98EGShbjg5C2TThuuJXB9XYnQvgEQrexxgddl2j4qRjmSWuQY1ym0Se6voMaEFiun1kyf8LA9LsuoQmlxLIrMN4wIDAQAB');
  const rsaEncrypted = rsa.encrypt(aesEncryptedBase64) //rsa密文
  return rsaEncrypted;
}
