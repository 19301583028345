/*
 * @Description  :
 * @Version      : 2.0
 * @Autor        : lixia
 * @Date         : 2024-01-16 14:52:58
 * @LastEditors  : lixia
 * @LastEditTime : 2024-03-27 13:50:29
 */
import { lazy } from 'react'
import { Navigate } from 'react-router-dom'

const MenuRoutes = [
  {
    path: 'orgManage',
    name: '园区管理',
    redirect: <Navigate to="/orgManage/orgManageHome" replace={true} />,
    children: [
      {
        path: 'orgManage/orgManageHome',
        name: '园区管理',
        icon: 'icon-rizhiguanli',
        component: lazy(() => import('@pages/orgManage')),
        expandFlag: false, // 如果子菜单全部加了hideInMenu:true，必须加此属性；反之如果加了此属性子菜单都不会出现在左侧菜单栏
        children: [
          {
            path: 'orgManage/orgManageHome/infoConfig',
            name: '信息配置',
            icon: 'icon-yuanquguanli',
            component: lazy(() => import('@pages/orgManage/subPage/infoConfig')),
            hideInMenu: true,
            returnButtonFlag: true // 面包屑是否展示返回按钮
          },
          {
            path: 'orgManage/orgManageHome/projectDetail',
            name: '项目详情',
            icon: 'icon-yuanquguanli',
            component: lazy(() => import('@pages/orgManage/subPage/projectDetail')),
            hideInMenu: true,
            returnButtonFlag: true // 面包屑是否展示返回按钮
          }
        ]
      }
    ]
  },
  {
    path: 'apiDocumentation',
    name: '接口文档',
    component: lazy(() => import('@pages/apiDocumentation'))
  },
  {
    path: 'message',
    name: '消息中心',
    redirect: <Navigate to="/message/index" replace={true} />,
    children: [
      {
        path: 'message/index',
        name: '消息中心',
        icon: 'icon-rizhiguanli',
        component: lazy(() => import('@pages/messageCenter'))
      }
    ]
  }
]

export default MenuRoutes
