import { createSlice } from '@reduxjs/toolkit'

export const loginUserSlice = createSlice({
  name: 'loginUser',
  initialState: {
    userName: '',
    userId: '',
    mobile: '',
    companyName: '',
    appid: '',
    isCreatedSecret: false
  },
  reducers: {
    // 保存登录用户信息
    setLoginUserInfo: (state, { payload: { userName, userId, mobile } }) => {
      state.userName = userName
      state.userId = userId
      state.mobile = mobile
      // 每次登陆后会重置
      state.companyName = ''
      state.appid = ''
      state.isCreatedSecret = false
    },
    // 保存公司信息
    setCompanyInfo: (state, { payload: { companyName, appid, isCreatedSecret } }) => {
      state.companyName = companyName
      state.appid = appid
      state.isCreatedSecret = isCreatedSecret // 密钥生成状态
    },
    // 保存密钥重置状态信息
    setCreatedSecretStatus: (state, { payload }) => {
      state.isCreatedSecret = payload // 更新密钥生成状态
    }
  }
})

export const { setLoginUserInfo, setCompanyInfo, setCreatedSecretStatus } = loginUserSlice.actions
export default loginUserSlice.reducer
